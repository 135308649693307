import { useRef, useState } from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Chip, FlexCenter } from '../../../../components';
import { AddIcon } from '../../../../themes';
import { ApplicationTagsInput } from './ApplicationTagsInput';

export function ApplicationTag({ tag }) {
  const theme = useTheme();
  return (
    <Box mr={1}>
      <Chip
        label={tag.name}
        labelVariant='label'
        color={theme.palette.text.secondary}
      />
    </Box>
  );
}

export function ManageApplicationTags({
  currentTags,
  applicationId,
  updateTags,
}) {
  const tagsButtonRef = useRef(null);
  const [tagsAnchorEl, setTagsAnchorEl] = useState(null);

  return (
    <>
      <FlexCenter>
        {currentTags.length ? (
          currentTags.map((t, i) => <ApplicationTag tag={t} key={i} />)
        ) : (
          <Typography variant='label' color='text.secondary' mr={1}>
            Application labels
          </Typography>
        )}

        <Tooltip title='Manage labels'>
          <IconButton
            onClick={() => setTagsAnchorEl(tagsButtonRef.current)}
            ref={tagsButtonRef}
            sx={{
              border: '1px dashed #B9BDD4',
              borderRadius: '3px',
              padding: 0,
              height: '21px',
              width: '21px',
            }}
          >
            <AddIcon fontSize='xsmall' />
          </IconButton>
        </Tooltip>
      </FlexCenter>
      <ApplicationTagsInput
        applicationId={applicationId}
        initialTags={currentTags}
        anchorEl={tagsAnchorEl}
        setAnchorEl={setTagsAnchorEl}
        open={Boolean(tagsAnchorEl)}
        updateTags={updateTags}
      />
    </>
  );
}

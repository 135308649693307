import { Box, BoxProps, Typography } from '@mui/material';

import { generatePath, Routes } from '../../../lib';
import { ListQueryParams } from '../ListViews/helpers/SharedListConfig';
import { ResidentListTypes } from '../ListViews/helpers/ResidentListConfig';

export const SectionHeader = ({
  title1,
  title2,
  color,
}: {
  title1: string;
  title2: string;
  color: string;
}) => (
  <Box display='flex' alignItems='baseline'>
    <Typography component='span' fontSize='24px' fontWeight='bold'>
      {title1}
    </Typography>
    <Typography component='span' fontSize='24px' fontWeight={200}>
      {title2}
    </Typography>
    <ColoredBox color={color} marginLeft={0.25} />
  </Box>
);

export const ColoredBox = ({
  color,
  size = 6,
  borderRadius = '1px',
  ...boxProps
}: {
  color: string;
  size?: number;
  borderRadius?: string;
} & BoxProps) => (
  /* @ts-ignore // we want an empty Box here */
  <Box
    height={size}
    width={size}
    backgroundColor={color}
    borderRadius={borderRadius}
    {...boxProps}
  />
);

export const generateLinkToResidentList = (
  selectedFacilityIds: string[],
  queryParams: Record<string, any>,
) =>
  generatePath(
    Routes.residentsList.path,
    {},
    {
      [ListQueryParams.listType]: ResidentListTypes.All,
      [ListQueryParams.facilityIds]: selectedFacilityIds,
      ...queryParams,
    },
  );

export type GenerateLinkToResidentListType = (
  arg0: Record<string, any>,
) => string;

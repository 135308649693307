import { authGet, formatUsers, authPost } from '../../lib';
import { ui } from './state';
const { actions } = ui;

export const uiActions = {
  ...actions,
  updateCompanySetting(companyId, companySetting) {
    return async (dispatch) => {
      const response = await authPost(`/Companies/${companyId}/settings`, {
        companySetting,
      });
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setCompanySettings(data));
      }
    };
  },
  getCompanySettings(companyId) {
    return async (dispatch) => {
      const response = await authGet(`/Companies/${companyId}/settings`);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setCompanySettings(data));
      }
    };
  },
  getUserSettings() {
    return async (dispatch) => {
      const response = await authGet(`/users/settings`);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setUserSettings(data));
      }
    };
  },
  getListViews(category) {
    return async (dispatch) => {
      const response = await authGet(['/users/listViews', { category }]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setListViews(data));
      }
    };
  },
  getCompanyListViews(category) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      dispatch(actions.setCompanyListViewsLoading(true));
      const response = await authGet([
        `/companies/${companyID}/settings/current-lists`,
        { category },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(
          actions.setCompanyListViews({
            data,
            loading: false,
            hasNoCompanyListViews: data.length === 0,
          }),
        );
      } else {
        dispatch(actions.setCompanyListViewsLoading(false));
      }
    };
  },
  // only users that belong to this company
  getBasicCompanyUsers(userType) {
    return async (dispatch, getState) => {
      const response = await authGet(['/CompanyUsers/basic', { userType }]);
      const { data, error } = response;
      if (!error) {
        const companyId = getState().authPersist.data?.companyID;
        dispatch(actions.setCompanyUsers(formatUsers(data, companyId)));
      }
    };
  },
  getPotentialCompanyUsers(userType) {
    return async (dispatch, getState) => {
      const response = await authGet([
        '/CompanyUsers/potentially-allowed/basic',
        { userType },
      ]);
      const { data, error } = response;
      if (!error) {
        const companyId = getState().authPersist.data?.companyID;
        dispatch(
          actions.setPotentialCompanyUsers(formatUsers(data, companyId)),
        );
      }
    };
  },
  getMedicaidCaseworkers(filingStateId) {
    return async (dispatch) => {
      const response = await authGet([
        'companyContacts/basic',
        {
          filingStateId,
          type: 1,
        },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setMedicaidCaseworkers(data));
      }
    };
  },
  getInsuranceCaseworkers() {
    return async (dispatch) => {
      const response = await authGet([
        'companyContacts/basic',
        {
          type: 2,
        },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setInsuranceCaseworkers(data));
      }
    };
  },

  getOutsourceContacts() {
    return async (dispatch) => {
      const response = await authGet([
        'companyContacts/basic',
        {
          type: 3,
        },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setOutsourceContacts(data));
      }
    };
  },

  getJudges() {
    return async (dispatch) => {
      const response = await authGet([
        'companyContacts/basic',
        {
          type: 4,
        },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setJudges(data));
      }
    };
  },
  getInsuranceCompanies() {
    return async (dispatch) => {
      const response = await authGet([
        'companyContacts/insuranceCompanies/basic',
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setInsuranceCompanies(data));
      }
    };
  },
  getBankAccounts({ caseId }) {
    return async (dispatch) => {
      const response = await authGet(`/cases/${caseId}/accounts/basic`);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setBankAccounts(data));
      }
    };
  },
  getTodosCount() {
    return async (dispatch) => {
      const response = await authGet('/alerts/task/count?isDue=true');
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setTodosCount(data.count));
      }
    };
  },
  trackView(viewType, entityID) {
    return async (dispatch) => {
      const response = await authPost('/users/track/view', {
        viewType,
        entityID,
      });
      const { error } = response;
      if (error) {
        dispatch(
          actions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
    };
  },
  getCounties(facilityStateId) {
    return async (dispatch) => {
      const response = await authGet([
        '/system/counties',
        {
          stateId: facilityStateId,
        },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setCounties(data));
      }
    };
  },
  getCurrentAnnouncement() {
    return async (dispatch) => {
      const response = await authGet('/users/announcements-current');
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setCurrentAnnouncement(data));
      }
    };
  },
  getAllAnnouncements(pageNumber) {
    return async (dispatch) => {
      const response = await authGet([
        '/users/announcements-list',
        {
          pageNumber,
          resultsPerPage: 10,
        },
      ]);
      const { data, error } = response;
      if (!error) {
        return { data };
      }

      return { error };
    };
  },

  getApplicationTagOptions() {
    return async (dispatch) => {
      const response = await authGet('/applications/tag-options');
      const { data, error } = response;
      if (data) {
        dispatch(actions.setApplicationTagOptions(data));
      } else {
        dispatch(
          uiActions.showError({
            message:
              error?.message ||
              'An error occurred while fetching label options',
          }),
        );
      }
    };
  },
};

import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import {
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import {
  ColoredIcon,
  ContentBox,
  FlexBetween,
  FlexCenter,
  FlexColumn,
} from '../../../components';

import {
  OpenAppsStatusBreakdownType,
  ResidentCountResult,
  SystemEnum,
  systemPersistSelectors,
} from '../../../state';
import { ArrowForwardIcon, InfoOutlinedIcon } from '../../../themes';
import {
  applicationStatusTypes,
  applicationTypes,
  dataTooltipStyles,
} from '../../../lib';

import { ListQueryParams } from '../ListViews/helpers/SharedListConfig';
import { middleDot } from '../../../lib/symbols';
import { ColoredBox, generateLinkToResidentList, SectionHeader } from './utils';
import { getColorForApplicationStatus } from '../caseDetails/application/applicationHelpers';

export const WorkflowSummaries = memo(function ({
  residentCounts,
  selectedFacilityIds,
}: {
  residentCounts: ResidentCountResult;
  selectedFacilityIds: string[];
}) {
  const theme = useTheme();

  return (
    <ContentBox>
      <SectionHeader
        title1='workflow'
        title2='summaries'
        color={theme.palette.info.main}
      />
      <Stack divider={<Divider />} marginTop={3}>
        <WorkflowsResidentsListItem
          data={{
            name: 'Nursing home applications',
            inProgressCount: residentCounts.nursingHomeApps.openAppsCount,
            neededCount: residentCounts.nursingHomeApps.appsNeededCount,
            breakdown: residentCounts.nursingHomeApps.openAppsStatusBreakdown,
          }}
          toLink={generateLinkToResidentList(selectedFacilityIds, {
            [ListQueryParams.applicationTypes]: applicationTypes.NursingHome,
          })}
        />

        <WorkflowsResidentsListItem
          data={{
            name: 'Renewals',
            inProgressCount: residentCounts.renewalApps.openAppsCount,
            neededCount: residentCounts.renewalApps.appsNeededCount,
            breakdown: residentCounts.renewalApps.openAppsStatusBreakdown,
          }}
          toLink={generateLinkToResidentList(selectedFacilityIds, {
            [ListQueryParams.applicationTypes]: applicationTypes.Renewal,
          })}
        />

        <WorkflowsResidentsListItem
          data={{
            name: 'Appeals',
            inProgressCount: residentCounts.appsInAppealCount,
            neededCount: residentCounts.appealNeededCount,
          }}
          toLink={generateLinkToResidentList(selectedFacilityIds, {
            [ListQueryParams.applicationStatuses]:
              applicationStatusTypes.Appealed,
          })}
        />

        <WorkflowsResidentsListItem
          data={{
            name: 'Other applications',
            inProgressCount: residentCounts.otherTypesApps.openAppsCount,
            neededCount: residentCounts.otherTypesApps.appsNeededCount,
            breakdown: residentCounts.otherTypesApps.openAppsStatusBreakdown,
          }}
          toLink={generateLinkToResidentList(selectedFacilityIds, {
            [ListQueryParams.applicationTypes]: Object.values(
              applicationTypes,
            ).filter(
              (t) =>
                t !== applicationTypes.NursingHome &&
                t !== applicationTypes.Renewal,
            ),
          })}
        />

        <WorkflowsResidentsListItem
          data={{
            name: 'Document requests',
            inProgressCount: residentCounts.docsDueCount,
            overdueCount: residentCounts.overdueDocsCount,
          }}
          toLink={generateLinkToResidentList(selectedFacilityIds, {
            [ListQueryParams.hasDocsDue]: true,
          })}
        />
      </Stack>
    </ContentBox>
  );
});

const WorkflowsResidentsListItem = ({
  data,
  toLink,
}: {
  data: WorkflowSummaryType;
  toLink: string;
}) => {
  const { name, inProgressCount, neededCount, overdueCount, breakdown } = data;

  return (
    <FlexBetween paddingY={1} alignItems='center'>
      <Typography variant='subtitle2'>{name}</Typography>
      <FlexCenter gap={1}>
        <FlexCenter>
          <Typography variant='label'>
            {inProgressCount} residents in progress
          </Typography>

          {breakdown && (
            <InfoTooltip
              title={name}
              totalCount={inProgressCount}
              data={breakdown}
            />
          )}
        </FlexCenter>
        {!!neededCount && (
          <>
            <Typography>{middleDot}</Typography>
            <Typography variant='caption' color='text.secondary'>
              {neededCount} needed
            </Typography>
          </>
        )}
        {!!overdueCount && (
          <>
            {middleDot}
            <Typography variant='caption' color='error'>
              {overdueCount} overdue
            </Typography>
          </>
        )}
        <IconButton component={Link} to={toLink}>
          <ColoredIcon Icon={ArrowForwardIcon} iconColor='action.primary' />
        </IconButton>
      </FlexCenter>
    </FlexBetween>
  );
};

const InfoTooltip = memo(function ({
  title,
  totalCount,
  data,
}: {
  title: string;
  totalCount: number;
  data: OpenAppsStatusBreakdownType;
}) {
  const openApplicationStatuses: SystemEnum[] = useSelector(
    systemPersistSelectors.openApplicationStatuses,
  );

  const tooltip = (
    <FlexColumn gap={2} padding={1}>
      <Typography variant='subtitle2'>
        {totalCount} {title}
      </Typography>

      <FlexColumn gap={0.5}>
        {openApplicationStatuses.map((s, i) => {
          const { count } = data.find((d) => d.key === s.id) || {};
          return (
            <FlexCenter key={i} gap={0.5}>
              <ColoredBox
                color={getColorForApplicationStatus(s.id)?.color}
                size={10}
                borderRadius='2px'
              />
              <Typography variant='caption' color='text.secondary'>
                {count || 0} {s.name}
              </Typography>
            </FlexCenter>
          );
        })}
      </FlexColumn>
    </FlexColumn>
  );

  return (
    <Tooltip
      title={tooltip}
      placement='top-end'
      sx={{ ml: '4px' }}
      componentsProps={dataTooltipStyles}
    >
      <InfoOutlinedIcon fontSize='tiny' />
    </Tooltip>
  );
});

type BaseWorkflowSummaryType = {
  name: string;
  inProgressCount: number;
  breakdown?: OpenAppsStatusBreakdownType;
};
type WorkflowSummaryType =
  | (BaseWorkflowSummaryType & { neededCount: number; overdueCount?: never })
  | (BaseWorkflowSummaryType & { neededCount?: never; overdueCount: number });

import { State } from '../types';

const emptyArray = [] as any;

export const systemPersistSelectors = {
  preferredScanner: (state: State) =>
    state.systemPersist.settings?.scanner?.preferredName,
  superAdminSelectedCompany: (state: State) =>
    state.systemPersist.superAdminCompanyHeader?.id,
  systemSettings: (state: State) => state.systemPersist.systemSettings,

  acCaseTypes: (state: State) =>
    state.systemPersist.systemSettings?.acCaseTypes || emptyArray,
  acCaseTypesObj: (state: State) =>
    state.systemPersist.systemSettings?.acCaseTypesObj,
  acApplicationTypes: (state: State) =>
    state.systemPersist.systemSettings?.acApplicationTypes || emptyArray,
  alertCategoryTypes: (state: State) =>
    state.systemPersist.systemSettings?.alertCategoryTypes || emptyArray,
  announcementTypes: (state: State) =>
    state.systemPersist.systemSettings?.announcementTypes || emptyArray,
  applicationStatusCategories: (state: State) =>
    state.systemPersist.systemSettings?.applicationStatusCategories ||
    emptyArray,
  applicationStatuses: (state: State) =>
    state.systemPersist.systemSettings?.applicationStatuses || emptyArray,
  applicationStatusObj: (state: State) =>
    state.systemPersist.systemSettings?.applicationStatusObj,
  authStatusTypes: (state: State) =>
    state.systemPersist.systemSettings?.authStatusTypes || emptyArray,
  bankAccountTypes: (state: State) =>
    state.systemPersist.systemSettings?.bankAccountTypes || emptyArray,
  burialArrangementTypes: (state: State) =>
    state.systemPersist.systemSettings?.burialArrangementTypes || emptyArray,
  caseNoteTypes: (state: State) =>
    state.systemPersist.systemSettings?.caseNoteTypes || emptyArray,
  caseworkerTypes: (state: State) =>
    state.systemPersist.systemSettings?.caseworkerTypes || emptyArray,
  clinicalEligibilityStatuses: (state: State) =>
    state.systemPersist.systemSettings?.clinicalEligibilityStatuses ||
    emptyArray,
  genericClinicalEligibilityStatuses: (state: State) =>
    state.systemPersist.systemSettings?.genericClinicalEligibilityStatuses ||
    emptyArray,
  companyTypes: (state: State) =>
    state.systemPersist.systemSettings?.companyTypes || emptyArray,
  companyUserTypes: (state: State) =>
    state.systemPersist.systemSettings?.companyUserTypes || emptyArray,
  deductionTypes: (state: State) =>
    state.systemPersist.systemSettings?.deductionTypes || emptyArray,
  dischargePlans: (state: State) =>
    state.systemPersist.systemSettings?.dischargePlans || emptyArray,
  documentTypesObj: (state: State) =>
    state.systemPersist.systemSettings?.documentTypesObj,
  expenseTypes: (state: State) =>
    state.systemPersist.systemSettings?.expenseTypes || emptyArray,
  facilityApplicationTypes: (state: State) =>
    state.systemPersist.systemSettings?.facilityApplicationTypes || emptyArray,
  facilityTypes: (state: State) =>
    state.systemPersist.systemSettings?.facilityTypes || emptyArray,
  facilityTypesObj: (state: State) =>
    state.systemPersist.systemSettings?.facilityTypesObj,
  facilityCaseTypes: (state: State) =>
    state.systemPersist.systemSettings?.facilityCaseTypes || emptyArray,
  facilityCaseTypesObj: (state: State) =>
    state.systemPersist.systemSettings?.facilityCaseTypesObj,
  frequencyTypes: (state: State) =>
    state.systemPersist.systemSettings?.frequencyTypes || emptyArray,
  investmentAccountTypes: (state: State) =>
    state.systemPersist.systemSettings?.investmentAccountTypes || emptyArray,
  lifeInsuranceAccountTypes: (state: State) =>
    state.systemPersist.systemSettings?.lifeInsuranceAccountTypes || emptyArray,
  lifeInsuranceStatuses: (state: State) =>
    state.systemPersist.systemSettings?.lifeInsuranceStatuses || emptyArray,
  listSplitMode: (state: State) => state.systemPersist.settings?.listSplitMode,
  medicaidCaseworkerReponseTypes: (state: State) =>
    state.systemPersist.systemSettings?.medicaidCaseworkerReponseTypes ||
    emptyArray,
  mspTypes: (state: State) =>
    state.systemPersist.systemSettings?.mspTypes || emptyArray,
  nriStatuses: (state: State) =>
    state.systemPersist.systemSettings?.nriStatuses || emptyArray,
  openApplicationStatuses: (state: State) =>
    state.systemPersist.systemSettings?.openApplicationStatuses || emptyArray,
  pasrrDeterminationTypes: (state: State) =>
    state.systemPersist.systemSettings?.pasrrDeterminationTypes || emptyArray,
  pasrrLevel2Due: (state: State) =>
    state.systemPersist.systemSettings?.pasrrLevel2Due || emptyArray,
  pasrrStatusTypes: (state: State) =>
    state.systemPersist.systemSettings?.pasrrStatusTypes || emptyArray,
  payerTypes: (state: State) =>
    state.systemPersist.systemSettings?.payerTypes || emptyArray,
  paymentMethodTypes: (state: State) =>
    state.systemPersist.systemSettings?.paymentMethodTypes || emptyArray,
  positionTypes: (state: State) =>
    state.systemPersist.systemSettings?.positionTypes || emptyArray,
  residentClinicalStatuses: (state: State) =>
    state.systemPersist.systemSettings?.residentClinicalStatuses || emptyArray,
  rfmsStatuses: (state: State) =>
    state.systemPersist.systemSettings?.rfmsStatuses || emptyArray,
  showAlertsListView: (state: State) =>
    state.systemPersist.settings?.showAlertsListView,
  states: (state: State) =>
    state.systemPersist.systemSettings?.states || emptyArray,
  statesObj: (state: State) => state.systemPersist.systemSettings?.statesObj,
  systemCaseTags: (state: State) =>
    state.systemPersist.systemSettings?.systemCaseTags || emptyArray,
  toDoPriorityTypes: (state: State) =>
    state.systemPersist.systemSettings?.toDoPriorityTypes || emptyArray,
  trackerUserSettingTypes: (state: State) =>
    state.systemPersist.systemSettings?.trackerUserSettingTypes || emptyArray,
  uploadCategories: (state: State) =>
    state.systemPersist.systemSettings?.uploadCategories || emptyArray,
  webhookEventTypes: (state: State) =>
    state.systemPersist.systemSettings?.webhookEventTypes || emptyArray,
};

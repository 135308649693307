import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listActions } from './state';

// Example  ['users', 'users:id']
export const lists = [
  'residents',
  'applications',
  'expenses',
  'incomes',
  'paymentHistory',
  'users',
  'companyContacts',
  'facilities',
  'facilityContacts',
  'facilityNotes',
  'residentNotes',
  'residentsTransactions',
  'toDos',
  'auth',
  'alertsDone',
  'alertSettings',
  'pasrrLevel1',
  'pasrrLevel2',
  'clinicalNotes',
  'levelOfCares',
  'documents',
  'facilityAggregates',
  'mappings',
  'companyPayers',
  'companyCaseTags',
  'coverages',
  'coveragePeriods',
  'adts',
  'saasUsers',
  'saasCompanies',
  'saasAnnouncements',
  'saasFacilities',
  'companyApplicationTags',
];

function RegisterLists() {
  const dispatch = useDispatch();

  useEffect(() => {
    const listResources = lists.reduce((acc, cur) => {
      acc[cur] = {
        name: cur,
        data: {},
        ids: [],
        params: {
          page: 0,
          numberOfRows: -1, // the total amount of rows in the DB
          rowsPerPage: undefined,
          order: undefined,
          orderBy: undefined,
          filter: {},
          selectedIds: [],
          total: 0,
          hasList: false,
        },
      };
      return acc;
    }, {});
    dispatch(listActions.registerLists(listResources));
  }, [dispatch]);

  return null;
}
export default memo(RegisterLists);

import { useEffect, useState } from 'react';
import { Snackbar, Button, Typography } from '@mui/material';
import * as serviceWorker from './serviceWorker';
import { FlexCenter, FlexColumn } from './components';

export function ServiceWorkerWrapper() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.addEventListener('statechange', (event) => {
      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });

    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
  };

  return (
    <Snackbar
      open={showReload}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <FlexCenter
        sx={{ background: 'linear-gradient(180deg, #061BEE 0%, #0514B3 100%)' }}
        boxShadow={
          '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)'
        }
        borderRadius={'3px'}
        color={'#FFF'}
        height={53}
        overflow={'hidden'}
        pl={0.5}
        pr={2.5}
        py={1}
      >
        <img src='/images/Aidace_Update_Available.svg' alt='' width='39' />
        <FlexColumn ml={3} mr={3.5}>
          <Typography variant='subtitle2'>An update is available!</Typography>
          <Typography variant='label'>
            Reload to avoid functionality lags.
          </Typography>
        </FlexColumn>
        <Button
          variant='text'
          onClick={reloadPage}
          sx={{ color: '#FFF', fontWeight: 700, fontSize: 16 }}
        >
          RELOAD TO UPDATE
        </Button>
      </FlexCenter>
    </Snackbar>
  );
}
